import React, { useState, useContext } from 'react'
import { MenuItem } from '../../@types/types'
import { EventContext, setEvent } from '../../reducers/event'
import { UiContext, setAction, setAlert } from '../../reducers/ui'

import { MobileHeader } from './MobileHeader'
import { Wrapper, MenuWrapper, MobileMenuItem } from './styles'

interface Props {
  menuItems?: MenuItem[]
  onClose: Function
  open?: boolean
}

/* eslint react-hooks/exhaustive-deps: "off" */
const MobileNavigation: React.FC<Props> = ({ menuItems, onClose, open }) => {
  const [showNav, setShowNav] = useState<boolean>(false)

  const { dispatch: eventDispatch, state: eventState } = useContext(EventContext)
  const { dispatch: uiDispatch, state: uiState } = useContext(UiContext)

  const onToggle = () => {
    if (uiState.currentAction !== '') {
      uiDispatch(setAction(''))
      onClose()
      return
    }
    setShowNav(!showNav)
  }

  const updateMenu = ({ instance, alert }) => {
    if (alert) {
      uiDispatch(setAlert(alert))
      return
    }
    if (instance) {
      eventDispatch(setEvent('MOVE_TO_OBJECT', instance))
      setShowNav(false)
    }
  }

  return (
    <Wrapper open={open}>
      <>
        <MobileHeader toggle={onToggle} menuVisible={uiState.open || !eventState.flyThroughViewed} />
        {showNav && (
          <MenuWrapper>
            {menuItems.map(({ title, instance, alert }, idx) => (
              <MobileMenuItem 
                dangerouslySetInnerHTML={{ __html: title }} 
                key={idx} 
                onClick={() => updateMenu({ instance, alert })} 
                className="ripple" 
              />
            ))}
          </MenuWrapper>
        )}
      </>
    </Wrapper>
  )
}

export { MobileNavigation }
