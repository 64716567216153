export const hasSeenFlyThrough = () => {
  const flyThroughSeen = localStorage.getItem('FlyThroughSeen')
  if (flyThroughSeen === 'true') {
    return true
  } else {
    return false
  }
}

export const setHasSeenFlyThrough = () => {
  localStorage.setItem('FlyThroughSeen', 'true')
}
