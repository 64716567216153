import { pdfContentUrl } from '../../api'

export const CRSwNP_DOWNLOADS = {
  name: 'CRSwNP Totum',
  title: 'CRSwNP',
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'EFFICACY & SAFETY',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/crswnp-efficacy-and-safety.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'UPPER AND LOWER AIRWAY INFLAMMATION',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/upper-and-lower-airway-inflammation.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'PATIENT BROCHURE',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/crswnp-patient-brochure.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'DUPIXENT 300 mg PRE-FILLED PEN',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/dupixent-300mg-pre-filled-pen.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'DUPIXENT DOSING',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/dupixient-dosing.pdf`,
        },
      },
    },
  ],
}
