import { videoContentUrl, contentUrl } from '../../api'

export const DERMATITIS_VIDEOS = {
  name: 'Atopic Dermatitis Screen',
  title: 'ATOPIC DERMATITIS',
  overlay: {
    type: "ClickableImage",
    options: {
      src: `${contentUrl}/images/StudyDesigns.png`,
      srcMobile: `${contentUrl}/images/StudyDesignsMobile.png`,
    },
  },
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'MECHANISM OF ACTION (MOA)',
      content: {
        contentTemplate: 'VIDEO',
        content: {
          src: `${videoContentUrl}/atopic-dermatitis-moa.mp4`,
          poster: `${videoContentUrl}/posters/AD-MOAVideo.png`,
        },
      },
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'REAL PATIENT STORIES',
      subItems: [
        {
          subMenuName: 'BRITTON-AVID HIKER <br />MODERATE DISEASE',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/patient-story-britton.mp4`,
            poster: `${videoContentUrl}/posters/AD-BrittonVideo.png`,
          },
        },
        {
          subMenuName: 'ANNIE-HIGH SCHOOL <br />STUDENT ATHLETE <br />SEVERE DISEASE',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/patient-story-annie.mp4`,
            poster: `${videoContentUrl}/posters/AD-AnnieVideo.png`,
          },
        },
        {
          subMenuName: 'KRISTY-RETIREE <br/>SEVERE DISEASE',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/patient-story-kristy.mp4`,
            poster: `${videoContentUrl}/posters/AD-KristyVideoPoster.png`,
          },
        },
        {
          subMenuName: 'RACHEL-NATURE LOVER <br />SEVERE DISEASE',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/patient-story-rachel.mp4`,
            poster: `${videoContentUrl}/posters/AD-RachelVideo.png`,
          },
        },
      ],
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'CLINCAL PROFILE',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          src: `${contentUrl}/images/StudyDesigns.png`,
          srcMobile: `${contentUrl}/images/StudyDesignsMobile.png`,
          alt: "StudyDesigns",
        },
      },
    },
  ],
}
