import React, { useState, useContext } from 'react'
import { motion } from 'framer-motion'
import { NavigationWrapper, InnerWrapper, NavToggleStatic, NavToggle, UL, NavMenu } from './styles'
import isMobile from '../../utils/mobileDetector'
import ListItem from './ListItem'
import { EventContext, setEvent } from '../../reducers/event'
import { UiContext, setAlert } from '../../reducers/ui'

interface Props {
  onTogglePlayPause?: () => void
  onPlay?: () => void
  onPause?: () => void
  menuItemClicked?: (val: string) => void
  menuItems?: MenuItem[]
  sceneData?: SceneData
  scene?: string
  loaded?: boolean
  isPaused?: boolean
}

/* eslint react-hooks/exhaustive-deps: "off" */
const DesktopNavigation: React.FC<Props> = ({ menuItems, loaded, open }) => {
  const { dispatch: eventDispatch } = useContext(EventContext)
  const { dispatch: uiDispatch } = useContext(UiContext)
  loaded = true
  const [showNav, setShowNav] = useState<boolean>(false)

  const menuToggle = () => {
    setShowNav(!showNav)
  }

  const navigateTo = (instance: string, alert: string) => {
    if (alert) {
      uiDispatch(setAlert(alert))
      return
    }
    eventDispatch(setEvent('MOVE_TO_OBJECT', instance))
    menuToggle()
  }

  return (
    <>
      {loaded && (
        <>
          <motion.div
            initial={{ y: '250vh' }}
            animate={{ y: showNav ? '250vh' : '98vh' }}
            transition={{ ease: 'easeInOut', delay: showNav ? 0 : 0.3 }}
          >
            <NavToggleStatic onClick={menuToggle} open={open}>
              <div className="top">
                <div className="top-arrow"></div>
              </div>
              <div className="bottom">MENU</div>
            </NavToggleStatic>
          </motion.div>
          <motion.div
            style={{
              height: '100%',
              position: 'absolute',
              bottom: 0,
              left: isMobile ? 0 : '50%',
              zIndex: 1000,
            }}
            initial={{ y: '100%' }}
            animate={{ y: showNav ? 0 : '100%' }}
            transition={{ ease: 'easeInOut', delay: showNav ? 0.2 : 0 }}
          >
            <NavigationWrapper>
              <InnerWrapper>
                <NavMenu>MENU</NavMenu>
                <UL>
                  {menuItems.map(({ title, instance, alert }, idx) => (
                    <ListItem
                      key={idx}
                      title={title}
                      onClick={() => {
                        navigateTo(instance, alert)
                      }}
                    />
                  ))}
                </UL>
                <NavToggle onClick={menuToggle}>
                  <div className="top-arrow_close"></div>
                </NavToggle>
              </InnerWrapper>
            </NavigationWrapper>
          </motion.div>
        </>
      )}
    </>
  )
}
export { DesktopNavigation }
