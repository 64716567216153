export * from './asthma'
export * from './atopic-dermatitis'
export * from './CRSwNP'
export * from './dosing'

export enum ContentTypes {
  ASTHMA_DOWNLOADS = 'ASTHMA_DOWNLOADS',
  ASTHMA_VIDEOS = 'ASTHMA_VIDEOS',
  ATOPIC_DOWNLOADS = 'DERMATITIS_DOWNLOADS',
  ATOPIC_VIDEOS = 'DERMATITIS_VIDEOS',
  CRSwNP_DOWNLOADS = 'CRSwNP_DOWNLOADS',
  CRSwNP_VIDEOS = 'CRSwNP_VIDEOS',
  DOSING_DOWNLOADS = 'DOSING_DOWNLOADS',
}

export const contentNavigation = [
  { title: 'ATOPIC DERMATITIS VIDEOS & DATA', instance: ContentTypes.ATOPIC_VIDEOS },
  { title: 'ATOPIC DERMATITIS DOWNLOADS', instance: ContentTypes.ATOPIC_DOWNLOADS },
  { title: 'ASTHMA VIDEOS', instance: ContentTypes.ASTHMA_VIDEOS },
  { title: 'ASTHMA DOWNLOADS', instance: ContentTypes.ASTHMA_DOWNLOADS },
  { title: 'CRSwNP VIDEOS', instance: ContentTypes.CRSwNP_VIDEOS },
  { title: 'CRSwNP DOWNLOADS', instance: ContentTypes.CRSwNP_DOWNLOADS },
  { title: '<i>DUPIXENT MyWay</i>&reg;', instance: ContentTypes.DOSING_DOWNLOADS },
  { title: 'CONTACT A REP', alert: 'ContactRep' },
]
