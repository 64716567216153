import { videoContentUrl } from '../../api'

export const CRSwNP_VIDEOS = {
  name: 'CRSwNP Screen',
  title: 'CRSwNP',
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'MECHANISM OF ACTION (MOA)',
      content: {
        contentTemplate: 'VIDEO',
        content: {
          src: `${videoContentUrl}/crswnp-moa.mp4`,
          poster: `${videoContentUrl}/posters/CRSwNP-MOAVideo.png`,
        },
      },
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'EXPERT DISCUSSIONS',
      subItems: [
        {
          subMenuName: 'DR SHER: CRSwNP ENDPOINTS AND DATA',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-crswnp-endpoints-&-data.mp4`,
            poster: `${videoContentUrl}/posters/CRSwNP-DrSherVideo1.png`,
          },
        },
        {
          subMenuName: 'DR SHER: CRSwNP POOLED ANALYSIS',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-crswnp-pooled-analyses.mp4`,
            poster: `${videoContentUrl}/posters/CRSwNP-DrSherVideo2.png`,
          },
        },
        {
          subMenuName: 'DR SHER: DISCUSSION OF MOA',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-impact-of-dual-inhibition.mp4`,
            poster: `${videoContentUrl}/posters/CRSwNP-DrSherVideo3.png`,
          },
        },
        {
          subMenuName: 'DR SHER: WHAT IS TYPE 2 INFLAMMATION?',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-what-is-type-2-inflammation.mp4`,
            poster: `${videoContentUrl}/posters/CRSwNP-DrSherVideo4.png`,
          },
        },
      ],
    },
  ],
}
