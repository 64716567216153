import React, { useContext, useEffect, lazy, Suspense, useState } from 'react'
import styled from 'styled-components'
import * as CONTENT from './config/content/'
import { DesktopNavigation, MobileNavigation } from './components/navigation'
import config from './config/config.json'
import sceneConfig from './config/sceneConfig'
import { UiContext, setShowPopup, setOpen, setAlert } from './reducers/ui'
import { EventContext, setEvent, setFlyThroughActive, setFlyThroughViewed } from './reducers/event'

import {
  INTRO_CONTENT,
  CONTACTAREP_CONTENT,
  MOBILELANDSCAPEOVERLAY_CONTENT,
  SKIPFLYTHROUGH_CONTENT,
} from './config/app'
import { gaModalViewed } from './utils/gaEvents'
import Alert from './components/Alert'
import BlackFade from './components/BlackFade'
import isMobile from './utils/mobileDetector'
import showWarning from './utils/showWarning'
import ContactRep from './components/ContactRep'
import MobileLandscapeOverlay from './components/Widgets/MobileLandscapeOverlay'
import { hasSeenFlyThrough, setHasSeenFlyThrough } from './utils/localStorage'

const ContentWithSidebar = lazy(() => import('./components/Container/ContentWithSidebar'))
const Scene = lazy(() => import('./components/scene'))
const Intro = lazy(() => import('./components/Intro'))

const SkipFlythrough = styled.p<{ open: boolean }>`
  bottom: 5%;
  color: white;
  font-size: 1.25rem;
  left: 50%;
  opacity: ${(props) => (props.open ? '0' : '1')};
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.5s ease-in-out 0.75s;
  text-shadow: 0 0 4px black;
  z-index: 100;
  :hover {
    color: #f9f9f9;
    cursor: pointer;
    font-size: 1.3rem;
  }
`

// gaInitalise({ gtmId: process.env.GTM_ID || 'GTM-K2HL228' })

const App = () => {
  const [content, setContent] = useState('')
  const { state: uiState, dispatch: uiDispatch } = useContext(UiContext)
  const { state: eventState, dispatch: eventDispatch } = useContext(EventContext)

  const [blackFadeClass, setBlackFadeClass] = useState('hidden')

  useEffect(() => {
    if (uiState?.currentAction !== '') {
      setContent(uiState.currentAction)
      uiDispatch(setShowPopup(true))
    }
  }, [uiState.currentAction, uiDispatch])

  useEffect(() => {
    if (eventState.eventName === 'END_FLY_THROUGH') {
      onFade()
    }
  }, [eventState]) // eslint-disable-line

  useEffect(() => {
    if (hasSeenFlyThrough()) {
      eventDispatch(setFlyThroughViewed(true))
    }
  }, []) // eslint-disable-line

  const onClose = () => {
    uiDispatch(setShowPopup(false))
    eventDispatch(setEvent('MOVE_TO_START'))
  }

  const onAlertClose = () => {
    uiDispatch(setAlert(''))
  }

  const onOpen = () => {
    uiDispatch(setOpen(false))
  }

  const onFade = (dispatchEvents = true) => {
    setBlackFadeClass('visible')
    setTimeout(() => {
      if (dispatchEvents) {
        eventDispatch(setEvent('CANCEL_FLY_THROUGH'))
      }
      eventDispatch(setFlyThroughActive(false))
      eventDispatch(setFlyThroughViewed(true))
      setBlackFadeClass('click-through')
      setHasSeenFlyThrough()
    }, 1200)
  }

  return (
    <>
      {isMobile && <MobileLandscapeOverlay content={MOBILELANDSCAPEOVERLAY_CONTENT} />}
      <Suspense fallback={<></>}>
        <Intro isReady={uiState.isReady} content={INTRO_CONTENT} open={uiState.open} setOpen={onOpen} />
      </Suspense>
      {!eventState.flyThroughViewed && (
        <>
          {(blackFadeClass === 'hidden') && (
            <SkipFlythrough onClick={onFade} open={uiState.open}>
              {SKIPFLYTHROUGH_CONTENT.skipMessage}
            </SkipFlythrough>
          )}
          <BlackFade onClick={onFade} className={`${blackFadeClass}`} />
        </>
      )}

      {isMobile ?
        <MobileNavigation menuItems={CONTENT.contentNavigation} onClose={onClose} open={false} />
        : !uiState.showPopup &&
          !eventState.cameraMoving &&
          !eventState.flyThroughActive && (
            <DesktopNavigation menuItems={CONTENT.contentNavigation} open={uiState.open} />
          )}
      {uiState.showPopup && content && (
        <>
          <Suspense fallback={<></>}>
            <ContentWithSidebar
              contents={CONTENT[content]}
              onSkip={() => {}}
              onClose={onClose}
              onOpen={(name) => gaModalViewed({ name })}
              onEnded={() => {}}
            />
          </Suspense>
        </>
      )}
      <Suspense fallback={<></>}>
        <Scene
          id="aaaai-scene"
          file="aaaai.gltf"
          path="https://uploads.dupixentvirtualbooth.com/uscongress/scenes/room/"
          config={config}
          sceneConfig={sceneConfig}
        />
      </Suspense>
      <Alert
        message="For an optimal experience, please switch your browser to Google Chrome."
        closeText="OK"
        show={showWarning()}
      />
      {uiState.alert && <ContactRep setShowAlert={onAlertClose} content={CONTACTAREP_CONTENT} />}
    </>
  )
}

export default App
