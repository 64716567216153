import React from 'react'
import dupixentLogo from '../../assets/images/dupixent-logo.png'
import menuBurger from '../../assets/images/menu-burger.png'
import { MenuHeaderStyled } from './styles'

interface Props {
  toggle: () => void
  menuVisible?: boolean
}

export const MobileHeader: React.FC<Props> = ({ toggle, menuVisible }) => {
  console.log(menuVisible)
  return (
    <MenuHeaderStyled>
      <>
        <img src={dupixentLogo} alt="dupixentLogo" width="163" />
        {!menuVisible && (
          <div onClick={toggle}>
            <img src={menuBurger} style={{ cursor: 'pointer', float: 'right' }} alt="" width="50%" height="50%" />
          </div>
        )}
      </>
    </MenuHeaderStyled>
  )
}
