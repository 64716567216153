import { pdfContentUrl } from '../../api'

export const ASTHMA_DOWNLOADS = {
  name: 'Asthma Totum',
  title: 'EOSINOPHILIC OR<br />OCS-DEPENDENT ASTHMA',
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'GUIDE FOR YOUR PATIENTS PRESCRIBED DUPIXENT',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/guide-for-your-patients-prescribed-dupixient.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'EFFICACY & SAFETY DATA',

      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/asthma-efficacy-and-safety.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'PATIENT BROCHURE',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/asthma-patient-brochure.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'DUPIXENT 300 mg PRE-FILLED PEN',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/dupixent-300mg-pre-filled-pen.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'DUPIXENT DOSING',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/dupixient-dosing.pdf`,
        },
      },
    },
  ],
}
