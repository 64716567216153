import React, { createContext, useReducer } from 'react'

const initialState = {
  currentAction: '',
  isReady: false,
  showPopup: false,
  alert: '',
  open: true,
}

// types
export const SET_ACTION = 'SET_ACTION'
export const SET_ISREADY = 'SET_ISREADY'
export const SET_SHOWPOPUP = 'SET_SHOWPOPUP'
export const SET_SHOWALERT = 'SET_SHOWALERT'
export const SET_OPEN = 'SET_OPEN'

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ACTION:
      return {
        ...state,
        currentAction: action.currentAction,
      }
    case SET_ISREADY:
      return {
        ...state,
        isReady: action.isReady,
      }
    case SET_SHOWPOPUP:
      return {
        ...state,
        showPopup: action.showPopup,
      }
    case SET_SHOWALERT:
      return {
        ...state,
        alert: action.alert,
      }
    case SET_OPEN:
      return {
        ...state,
        open: action.open,
      }
    default:
      return state
  }
}

// actions
// add action type
export const setAction = (action: string) => {
  return {
    type: SET_ACTION,
    currentAction: action,
  }
}

export const setShowPopup = (show: boolean) => {
  return {
    type: SET_SHOWPOPUP,
    showPopup: show,
  }
}

export const setIsReady = (ready: boolean) => {
  return {
    type: SET_ISREADY,
    isReady: ready,
  }
}

export const setAlert = (alert: string) => {
  return {
    type: SET_SHOWALERT,
    alert,
  }
}

export const setOpen = (open: boolean) => {
  return {
    type: SET_OPEN,
    open,
  }
}

export const UiContext = createContext({
  state: initialState,
  dispatch: null,
})

export const UiState = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <UiContext.Provider value={{ state, dispatch }}>{children}</UiContext.Provider>
}
