import { Color3, Vector3, Tools } from '@babylonjs/core'
import { GuiLabel } from '../@types/3DTypes'

export interface SceneConfig {
  debug?: boolean
  skyboxSize?: number
  skyboxColor?: Color3
  initialCameraPos: Vector3
  initialCameraTarget?: Vector3
  initialCameraRotation?: Vector3
  maxCameraRotationX?: number
  minCameraRotationX?: number
  maxCameraRotationY?: number
  minCameraRotationY?: number
  cameraSpeed?: number
  cameraMinZ?: number
  hemisphericLightDirection?: Vector3
  hemisphericLightIntensity?: number
  glowIntensity?: number
  skyboxTexture?: string
  skyboxTextureLevel?: number
  groundTextureLevel?: number
  videoBrightness?: number
  touchAngularSensibility?: number
  defaultGuiLabel?: GuiLabel
}

const sceneConfig: SceneConfig = {
  debug: false,
  cameraSpeed: 0.00000000001, // stops arrow key controls working
  hemisphericLightIntensity: 2,
  maxCameraRotationY: 3.7,
  minCameraRotationY: 2.7,
  minCameraRotationX: -0.1,
  maxCameraRotationX: 0.3,
  initialCameraPos: new Vector3(0, 5, 12.5),
  initialCameraRotation: new Vector3(Tools.ToRadians(15), Tools.ToRadians(180), 0),
  glowIntensity: 1,
  skyboxTexture: 'https://uploads.dupixentvirtualbooth.com/uscongress/content/images/sky-tex.png',
  skyboxTextureLevel: 1.1,
  groundTextureLevel: 2.5,
  videoBrightness: 1.25,
  touchAngularSensibility: 5000,
  defaultGuiLabel: {
    rect: {
      width: '290px',
      height: '140px',
      color: '#f2a900',
      thickness: 4,
      background: 'white',
    },
    label: {
      fontSize: '18px',
      color: '#258064',
      fontWeight: '600',
      text: '',
    },
    arrow: {
      fontSize: '28px',
      color: '#fff',
      fontWeight: '600',
      text: '>',
    },
    ellipse: {
      width: '30px',
      height: '30px',
      color: '#f2a900',
      background: '#f2a900',
      zIndex: 99999,
      linkOffsetX: '143px',
    },
  },
}

export default sceneConfig
